<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center w-100 mx-auto"
    v-limit="375"
  >
    <!-- Featured Login Options -->
    <div style="height: 214px">
      <appear v-if="loginPending">
        <loading-spinner />
      </appear>
      <appear v-else-if="featuredProvidersVisible">
        <div>
          <b-btn
            variant="light"
            class="bungalow-login-button mb-3"
            v-for="provider in featuredProviders"
            :key="provider.key"
            :class="provider.key"
            @click="login(provider.key)"
          >
            <div class="d-flex">
              <inline-svg :src="getProviderLogo(provider.key)" />
              <span class="d-flex flex-grow-1 justify-content-center align-items-center">
                Continue with {{ provider.name }}
              </span>
            </div>
          </b-btn>
          <b-btn
            variant="text"
            class="bungalow-login-button"
            @click="showAlternateProviders"
            data-cy="login-alternate-providers"
          >
            Other options
          </b-btn>
        </div>
      </appear>

      <!-- Alternate Options -->
      <appear v-else-if="alternateProvidersVisible">
        <div>
          <b-btn
            variant="light"
            class="bungalow-login-button mb-3"
            v-for="provider in alternateProviders"
            :key="provider.key"
            :class="provider.key"
            @click="login(provider.key)"
          >
            <div class="d-flex">
              <inline-svg :src="getProviderLogo(provider.key)" />
              <span class="d-flex flex-grow-1 justify-content-center align-items-center">
                Continue with {{ provider.name }}
              </span>
            </div>
          </b-btn>
          <b-btn
            variant="text"
            class="bungalow-login-button"
            @click="showFeaturedProviders"
            data-cy="login-featured-providers"
          >
            Back
          </b-btn>
        </div>
      </appear>
    </div>
    <!-- Disclaimer -->
    <div>
      <p class="font-xs charcoal-40--txt text-center mb-0">
        By clicking to login and continue you are agreeing to Bungalow’s
        <router-link to="/terms-of-service" @click.native="$root.$emit('closeModal')">
          <span class="blue-80--txt underline"> Terms of Use </span>
        </router-link>
        and
        <router-link :to="{ path: '/privacy-policy' }" @click.native="$root.$emit('closeModal')">
          <span class="blue-80--txt underline">Privacy Policy</span> </router-link
        >.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LoginButtons',
  props: {
    onLogin: {
      type: Function,
      default: () => {},
    },
    location: {
      type: String,
    },
    inviteGuid: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      featuredProviders: [
        { key: 'google', name: 'Google' },
        { key: 'apple', name: 'Apple' },
      ],
      alternateProviders: [
        // { key: 'facebook', name: 'Facebook' },
        { key: 'linkedin', name: 'LinkedIn' },
      ],
      featuredProvidersVisible: true,
      alternateProvidersVisible: false,
    }
  },
  watch: {
    authError(val) {
      val && this.showErrorNotification()
    },
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn', 'loginPending', 'activeUser', 'authError']),
  },
  methods: {
    ...mapActions('user', ['authenticate']),
    getProviderLogo(providerKey) {
      return require(`@/assets/auth/${providerKey}_logo.svg`)
    },
    showFeaturedProviders() {
      this.alternateProvidersVisible = false
      this.$nextTick(() => (this.featuredProvidersVisible = true))
    },
    showAlternateProviders() {
      this.featuredProvidersVisible = false
      this.$nextTick(() => (this.alternateProvidersVisible = true))
    },
    showErrorNotification() {
      this.$root.$emit('showNotification', {
        title: 'Login Failed:',
        text: this.authError,
        type: 'error',
        duration: 8000, // 8 secs
      })
    },
    login(provider) {
      this.$segment.track('Login', {
        category: 'Login Buttons',
        location: this.location,
        value: provider,
      })

      // Process Login request:
      this.authenticate({
        provider,
        onLogin: this.onLogin,
        inviteGuid: this.inviteGuid,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.bungalow-login-button {
  max-width: 100%;
  height: 52px;
  padding: 0 2rem !important;
}
</style>
