import ArticleRoutes from './Articles'
import ResidentRoutes from './Resident'
import Redirects from './redirects'
import PropertyRoutes from '@/pages/Properties'
import WebflowRoutes from '@/webflow'
import ContentfulStaticPages from '@/pages/ContentfulStaticPages'
import VendorExperience from './VendorExperience'
import WorkOrderHomeownerApproval from './WorkOrderHomeownerApproval'

export default [
  {
    path: '/',
    name: 'Home Page',
    component: () => import(/* webpackChunkName: "home" */ './HomePage/index.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      hideNav: true,
      hideFooter: true,
    },
    component: () => import(/* webpackChunkName: "login" */ '@/components/Auth/Login.vue'),
  },
  {
    path: '/oauth',
    name: 'Oauth',
    component: () => import(/* webpackChunkName: "login" */ '@/components/Auth/Oauth.vue'),
    meta: {
      analytics: 'Oauth Callback',
      hideNav: true,
      hideFooter: true,
    },
  },
  {
    path: '/user-invite',
    name: 'UserInvite',
    component: () => import(/* webpackChunkName: "userinvite" */ '@/components/UserInvite/UserInvite.vue'),
    meta: {
      hideNav: true,
      hideFooter: true,
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ './faq.vue'),
    props: (route) => ({ residentView: true }),
  },
  // {
  //   path: '/homeowner-faq',
  //   name: 'Homeowner FAQ',
  //   component: () => import(/* webpackChunkName: "faq" */ './faq.vue'),
  //   props: (route) => ({ residentView: false }),
  // },
  {
    path: '/covid-screening',
    redirect: '/faq',
  },
  {
    path: '/press',
    name: 'Press',
    component: () => import(/* webpackChunkName: "press" */ './press.vue'),
  },
  {
    path: '/community',
    name: 'Community',
    component: () => import(/* webpackChunkName: "community" */ './community.vue'),
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import(/* webpackChunkName: "reviews" */ './reviews.vue'),
  },
  {
    path: '/chat',
    name: 'chat',
    beforeEnter: (goingTo, comingFrom, next) => {
      return next({ path: '/profile', query: { showChats: true, ...goingTo.query } })
    },
  },
  {
    path: '/profile/:id?',
    name: 'Profile',
    props: true,
    component: () => import(/* webpackChunkName: "profile" */ './profile.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/my-tours-login',
    name: 'MyToursLogin',
    props: true,
    component: () => import(/* webpackChunkName: "tours" */ './my-tours-login.vue'),
    meta: { hideFooter: true },
  },

  // Booking Routes
  {
    path: '/my-tours',
    name: 'MyTours',
    component: () => import(/* webpackChunkName: "tours" */ './my-tours.vue'),
    meta: { requiresAuth: true, hideFooter: true },
  },
  {
    path: '/my-tours/:id',
    name: 'BookedTour',
    component: () => import(/* webpackChunkName: "tours" */ './my-booked-tour.vue'),
    props: (route) => ({
      tour: route.params.tour,
    }),
    meta: { requiresAuth: true, hideFooter: true },
  },
  {
    path: '/my-tours/:id/manage',
    name: 'ManageTour',
    component: () =>
      import(/* webpackChunkName: "tours" */ '@/pages/BookATour/containers/BookATourContainer'),
    props: (route) => ({
      tourProperty: route.params.property,
      scheduledTour: route.params.tour,
      tourID: route.params.id,
    }),
    meta: { requiresAuth: true, hideFooter: true },
    children: [
      {
        path: 'tour-schedule',
        name: 'ManageTourSchedule',
        meta: {
          requiresAuth: true,
          hideFooter: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "book-a-tour" */ '@/pages/BookATour/containers/BookATourContainer'
          ),
      },
      {
        path: 'confirm-identity',
        name: 'ManageTourIdentity',
        meta: {
          requiresAuth: true,
          hideFooter: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "book-a-tour" */ '@/pages/BookATour/containers/BookATourContainer'
          ),
      },
      {
        path: 'tour-confirmation',
        name: 'ManageTourConfirmation',
        meta: {
          requiresAuth: true,
          hideFooter: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "book-a-tour" */ '@/pages/BookATour/containers/BookATourContainer'
          ),
      },
    ],
  },
  {
    path: '/renew',
    name: 'Lease Renewal',
    component: () => import(/* webpackChunkName: "programs" */ './renew.vue'),
    props: (route) => ({
      defaultEmail: route.query.email,
      defaultFirstName: route.query.first_name,
      defaultLastName: route.query.last_name,
      defaultGUID: route.query.guid,
    }),
  },
  {
    path: '/data-request',
    alias: ['/account-request'],
    name: 'Data Request',
    component: () => import(/* webpackChunkName: "legal" */ './data-request.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/data-request-confirm',
    alias: ['/account-request-confirm'],
    name: 'Data Request Confirm',
    component: () => import(/* webpackChunkName: "legal" */ './data-request-confirm.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: () => import(/* webpackChunkName: "sitemap" */ './sitemap.vue'),
    children: [
      {
        path: ':page',
        name: 'sitemapPage',
      },
    ],
  },
  {
    path: '/transfer',
    alias: ['/transfers'],
    name: 'Transfer',
    component: () => import(/* webpackChunkName: "transfer" */ './transfer.vue'),
    meta: { requiresAuth: true, hideNav: true },
  },
  ...ArticleRoutes,
  ...VendorExperience,
  ...ResidentRoutes,
  ...WorkOrderHomeownerApproval,

  ...Redirects,
  ...WebflowRoutes,

  ...PropertyRoutes,

  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ './PageNotFound.vue'),
  },

  // Dynamically checks for an existing contentful page, if not found goes to 404 above
  ContentfulStaticPages,
]
