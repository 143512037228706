import queryString from 'query-string'

const resident = {
  paymentMethods: 'payment_methods/',
  balance: 'residents/balances/',
  statementLines: 'residents/statement_lines/',
  futureStatementLines: 'residents/future_statement_lines/',
  currentOccupancy: 'occupancies/current/',
  fullOccupancy: 'occupancies/?expand=~all',
  productSettings: 'product-settings/',
  document: (id) => `downloads/documents/url/${id}/`,
  invite: 'residents/invite/',
  createPaymentMethod: 'payment_methods/plaid/',
  createPayment: 'residents/payment/',
  createManualPaymentMethod: 'payment_methods/stripe/',
  verifyManualPaymentMethod: (id) => `payment_methods/stripe/${id}/verify/`,
  createCreditPaymentMethod: 'payment_methods/stripe/',
  getPaymentEstimate: 'residents/payment-estimate/',
  deletePaymentMethod: (id) => `payment_methods/${id}/`,
}

const supply = {
  intakeCreate: 'supply/vsvf/60ec87dd32df785b752c1bc6eedacdec',
}

const homeowner = {
  identity: 'homeowners/identity/',
  payoutMethods: 'homeowners/payout_methods/',
  statementLines: 'homeowners/statement_lines/',
  listProperties: 'homeowners/properties/',
  getPeriscopeEmbed: (id) => `homeowners/properties/${id}`,
  invite: 'homeowners/invite/',
  stripeIdentity: 'homeowners/identity/stripe/',
  createPayoutMethod: 'payout_methods/plaid/',
  createManualPayoutMethod: 'payout_methods/stripe/',
}

const phones = {
  list: 'phones/numbers/',
  numbers: 'phones/numbers/',
  verify: 'phones/verifications',
  patchOrDelete: (numberId) => `phones/numbers/${numberId}`,
}

const auth = {
  me: 'auth/me/',
  userInvite: (guid) => `auth/invite/${guid}/`,
}

const plaid = {
  linkToken: 'plaid/linktokens/',
}

const livingPreferences = {
  questions: 'living-preferences/questions/',
  responses: 'living-preferences/responses/',
}

const applications = {
  list: 'applications/applications/',
  source: 'applications/source/',
  preview: 'applications/source-preview/',
  openApplication: 'applications/open-application/',
  addPropertyToApplication: 'applications/application-properties/',
  animals: 'applications/animals/animals/',
  animalsById: (id) => `applications/animals/animals/${id}/`,
  applicantProfile: 'applications/applicant-profiles/',
  applicantProfileByUserId: (userId) => `applications/applicant-profiles/${userId}/`,
  roomSelections: (query) =>
    `applications/room-selection/${query ? `?${queryString.stringify(query)}` : ''}`,
  groups: {
    source: 'applications/groups/sources/',
    preview: 'applications/groups/source-preview/',
  },
  transfers: 'applications/transfers/',
}

const profile = {
  employment: 'profile/employment/',
  employmentById: (id) => `profile/employment/${id}/`,
  education: 'profile/education/',
  educationById: (id) => `profile/education/${id}/`,
  socialNetwork: 'profile/social-network/',
  socialNetworkById: (id) => `profile/social-network/${id}/`,
}

const profiles = {
  pronoun: 'profiles/',
  interests: 'interests/',
  profileByUserId: (userId) => `profiles/${userId}/`,
}

const chats = {
  myChats: 'meet-and-greet/meetings/',
  chatUser: 'chat/users/',
  pendingMessage: 'chat/pending-messages/',
  acceptInvite: 'applications/accept-property-invites/',
  createRoomSelectionFromMeeting: 'applications/room-selection-from-meeting/',
  declineMeeting: 'meet-and-greet/applicant-left-event/',
}

const profanity = {
  checkForProfanity: 'https://www.purgomalum.com/service/containsprofanity',
}

const constants = {
  list: (query) => `pricing/constants/?${queryString.stringify(query)}`,
}

const maintenance = {
  issues: (query) => `maintenance/issues/?${queryString.stringify(query)}`,
  issue: (issueId) => `maintenance/issues/${issueId}/`,
  issueComments: (query) => `maintenance/comments/?${queryString.stringify(query)}`,
  issueCommentsRead: 'maintenance/comments-read/',
  issueStates: (query) => `maintenance/states/?${queryString.stringify(query)}`,
  issueAttachments: (query) => `maintenance/attachments/?${queryString.stringify(query)}`,
}

const support = {
  classifications: (query) => `ops/support/classifications/?${queryString.stringify(query)}`,
  classification: (classificationId) => `ops/support/classifications/${classificationId}/`,
  submissionForms: (classificationId) => `ops/support/submission-forms/${classificationId}/`,
  issue: 'ops/support/issue/',
}

const reviews = {
  reviews: (query) => `reviews/requests/?${queryString.stringify(query)}`,
  review: (reviewId) => `reviews/requests/${reviewId}`,
  responses: 'reviews/responses/',
}

const files = {
  file: (fileId) => `files/${fileId}/`,
  files: 'files/',
}

const flags = {
  featureFlags: 'flags/',
}

const listings = {
  list: 'listings/properties/',
  detail: (idOrSlug) => `listings/properties/${idOrSlug}`,
  favorites: 'listings/favorites',
}

const markets = {
  list: 'markets?limit=30',
  detail: (idOrSlug) => `markets/${idOrSlug}`,
}

const tours = {
  availability: (propertyId) => `/listings/tours/availability/${propertyId}`,
  schedule: `listings/tours`,
}

const geo = {
  geolocation: '/api/geo-info',
}

const vendex = {
  experience: (guid) => `/vendex/${guid}`,
  list: `/vendex`,
  staffConfirmOverrideAmountAndCreateCard: (vendexGuid) => `vendex/card/${vendexGuid}`,
}

const workOrderHomeownerApproval = {
  approval: (guid) => `/maintenance/approvals/${guid}`,
}

const forms = {
  form: (guid) => `/forms/${guid}`,
  submission: `/forms/submission`,
  files: `/forms/submission/files`,
}

const pipedream = {
  vendex: {
    // https://pipedream.com/@bungalowliving/projects/proj_yesA6N/post-vendex-submission-handler-p_95CVAYq/inspect
    postSubmissionProcessing: 'https://eod4f2cnslt8uef.m.pipedream.net',
  },
}

export default {
  chats,
  pipedream,
  constants,
  geo,
  homeowner,
  resident,
  auth,
  applications,
  livingPreferences,
  profile,
  profiles,
  profanity,
  phones,
  plaid,
  supply,
  document,
  maintenance,
  markets,
  support,
  reviews,
  files,
  flags,
  listings,
  tours,
  vendex,
  workOrderHomeownerApproval,
  forms,
}
